import React from "react";
import decodeHTMLEntities from "../utils/decodeHTMLentities.js";
import { Link } from "gatsby";

const PostTitle = (props) => {
  const {
    domNode,
    selector
  } = props;
  const Content = () => {
    return (domNode.children && domNode.children.length && selector(domNode.children[0], "a"))
      ? (<Link to={ props.uri } title={ "Lire l'actualité "+decodeHTMLEntities(props.title) }>{ decodeHTMLEntities(props.title) }</Link>)
      : (<>{ decodeHTMLEntities(props.title) }</>);
  };
  const Wrapper = domNode.name;
  const attribs = { ... domNode.attribs, className: domNode.attribs.class };
  delete attribs.class;
  return <Wrapper {...attribs}><Content /></Wrapper>;
};

export default PostTitle;
